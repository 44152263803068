import { combineReducers } from "redux";
import cartReducer from "./cart";
import categoriaReducer from "./categoria";
import compareReducer from "./compare";
import contaUsuarioReducer from "./conta-usuario";
import demoReducer from "./demo";
import estabelecimentoReducer from "./estabelecimento";
import filtersReducer from "./filter";
import painelControleReducer from "./painel-controle";
import produtoReducer from "./produto";
import wishReducer from "./wishlist";

const rootReducer = combineReducers({
  cartList: cartReducer,
  data: produtoReducer,
  wishlist: wishReducer,
  demo: demoReducer,
  painelControle: painelControleReducer,
  compareList: compareReducer,
  filter: filtersReducer,
  estabelecimento: estabelecimentoReducer,
  categoria: categoriaReducer,
  contaUsuario: contaUsuarioReducer,
});

export default rootReducer;
