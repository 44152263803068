import { Md5 } from "ts-md5/dist/md5";
import ApiBase, { setLoginAuth } from "../api-base";
import { exibirMsgErroEx } from "../../utils";

export const realizarLogin = function(usuario, senha) {
  const vSenhaMd5 = Md5.hashStr(senha);
  const vParamLogin = "?email=" + usuario + "&senha=" + vSenhaMd5;
  return ApiBase.post("api/comprador/login" + vParamLogin)
    .then(function(response) {
      //console.log('realizarLogin:', response);
      let vResp = null;
      if (response.data) {
        vResp = response.data;
        vResp.dataHoraLogin = new Date();
        setLoginAuth(vResp);
      }
      return vResp;
    })
    .catch(function(errorConta) {
      console.error("realizarLogin:", errorConta);
      if (errorConta.errorMessage != null) {
        throw errorConta.errorMessage;
      } else {
        throw "Falha ao Realizar Login - Servidor Web-Commerce!";
      }
    });
};

export const criarUsuarioComprador = function(vDadoUsuario) {
  return ApiBase.post("api/comprador", vDadoUsuario)
    .then(function(response) {
      let vResp = null;
      if (response.data) {
        vResp = response.data;
      }
      return vResp;
    })
    .catch(function(errorConta) {
      console.error("criarConta:", errorConta);
      if(errorConta.errors !== null){
        errorConta.errors.forEach(error => {
          exibirMsgErroEx("Verifique os Dados", `\"${error.rejectedValue}\" ${error.defaultMessage}`, 5);
        });
        return null;
      }else {
        throw "Falha ao Criar Conta - Servidor Web-Commerce!";
      }
    });
};

export const atualizarConta = function(vDadoUsuario) {
  return ApiBase.put("api/comprador", vDadoUsuario)
    .then(function(response) {
      let vResp = null;
      if (response.data) {
        vResp = response.data;
      }
      return vResp;
    })
    .catch(function(errorConta) {
      console.error("atualizarConta:", errorConta);
      if(errorConta.errors !== null){
        errorConta.errors.forEach(error => {
          exibirMsgErroEx("Verifique os Dados", `\"${error.rejectedValue}\" ${error.defaultMessage}`, 5);
        });
        return null;
      } else {
        throw "Falha ao Atualizar Conta - Servidor Web-Commerce!";
      }
    });
};

export const redefinirSenhaConta = function(vDadoNovaSenha) {
  return ApiBase.put("api/comprador/senha", vDadoNovaSenha)
    .then(function(response) {
      let vResp = null;
      if (response.data) {
        vResp = response.data;
      }
      return vResp;
    })
    .catch(function(errorConta) {
      console.error("redefinirSenhaConta:", errorConta);
      if (errorConta.errorMessage != null) {
        throw errorConta.errorMessage;
      } else {
        throw "Falha ao Redefinir Senha Login - Servidor Web-Commerce!";
      }
    });
};

export const resetSenhaConta = function(vEmailSenha) {
  return ApiBase.put("api/comprador/reset-senha?login=" + vEmailSenha, {})
    .then(function(response) {
      let vResp = null;
      if (response.data) {
        vResp = response.data;
      }
      return vResp;
    })
    .catch(function(errorConta) {
      console.error("resetSenhaConta:", errorConta);
      if (errorConta.errorMessage != null) {
        throw errorConta.errorMessage;
      } else {
        throw "Falha ao Recuperar Senha Login Email - Servidor Web-Commerce!";
      }
    });
};
