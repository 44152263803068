import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    RECEBER_DADOS_ESTAB,
    RECEBER_DADOS_SISTEMA
} from "../constants/action-types";

const initialState = {
  dadosEstab: null,
  dadosConfigSistema: null,
  fetchBegin: true,
};

const estabelecimentoReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEBER_DADOS_ESTAB:
      return { ...state, dadosEstab: action.dadosEstab };

    case RECEBER_DADOS_SISTEMA:
      return { ...state, dadosConfigSistema: action.dadosConfigSistema };

    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: "Syndata-",
  key: "dados-estab",
  storage,
};

export default persistReducer(persistConfig, estabelecimentoReducer);
