import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    RECEBER_CONTA_USUARIO,
    RECEBER_LOGIN
} from "../constants/action-types";
//import {setLoginAuth} from '../api/api-base';

const initialState = {
  login: null,
  conta: null,
  fetchBegin: true,
};

const contaUsuarioReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEBER_LOGIN:
      /*console.log('contaUsuarioReducer RECEBER_LOGIN:', state.login);
            if (action.login != null && action.login.id != null) {
                setLoginAuth(action.login);
            } else  if (state.login != null && state.login.id != null) {
                setLoginAuth(state.login);
            }*/
      return { ...state, login: action.login };

    case RECEBER_CONTA_USUARIO:
      return { ...state, conta: action.conta };

    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: "Syndata-",
  key: "conta",
  storage,
};

export default persistReducer(persistConfig, contaUsuarioReducer);
