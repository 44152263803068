/*import axios from 'axios';

const API_URL = process.env.PUBLIC_URL;

// API to get products from mock server
export const getProducts = function () {
    return axios.get( API_URL + '/mock-server/product-demo1.json' )
        .then( function ( response ) {
            return response.data;
        } )
        .catch( function ( error ) {
            // handle error
            console.log( error );
        } );
}*/

//

import ApiBase from "../api-base";

export const obterProdutosDestaque = function() {
  let vNomePesq, vFiltroOrd;
  let vFiltroCateg;
  vNomePesq = "";
  vFiltroOrd = "_";
  vFiltroCateg = "_";
  const vCodLoja = 1;
  const vParamProd =
    "?codLoja=" +
    vCodLoja +
    "&descricao=" +
    vNomePesq +
    "&filtroOrdem=" +
    vFiltroOrd +
    "&filtroCategoria=" +
    vFiltroCateg;

  return ApiBase.get("api/produtos/pagina/pesquisa/1/26" + vParamProd)
    .then(function(response) {
      // console.log('obterProdutos: ', response);
      let vResp = [];
      if (response.data && response.data.itens) {
        /*for (const item of response.data) {                
                    if (item.indBloqueado && item.indBloqueado.includes('N')) {
                        vResp.push(item);
                    }
                }*/
        vResp = response.data.itens;
      }
      return vResp;
    })
    .catch(function(error) {
      // handle error
      console.error(error);
    });
};

export const obterProdutosPesquisa = function(
  descricaoProd,
  filtroCateg,
  filtroOrdem,
  numPag
) {
  let vNomePesq, vFiltroOrd;
  let vFiltroCateg;
  vNomePesq = "";
  if (descricaoProd) {
    vNomePesq = encodeURIComponent(descricaoProd);
  }
  vFiltroOrd = "_";
  vFiltroCateg = "_";
  if (filtroCateg && filtroCateg.length > 0 && filtroCateg !== "0") {
    vFiltroCateg = filtroCateg;
  }
  if (filtroOrdem && filtroOrdem.length > 0) {
    vFiltroOrd = filtroOrdem;
  }

  const vCodLoja = 1;
  const vParamProd =
    "?codLoja=" +
    vCodLoja +
    "&descricao=" +
    vNomePesq +
    "&filtroOrdem=" +
    vFiltroOrd +
    "&filtroCategoria=" +
    vFiltroCateg;

  return ApiBase.get("api/produtos/pagina/pesquisa/"+numPag+"/48" + vParamProd)
    .then(function(response) {      
      if (response.data) {        
        return response.data;
      } else {
        return [];
      }
    })
    .catch(function(error) {
      // handle error
      console.error(error);
    });
};

export const obterProdutoApresenta = function(idProd) {
  const vCodLoja = 1;
  const vUsuario = "semid";
  const vParamProd = "" + vCodLoja + "/" + idProd + "/" + vUsuario;

  return ApiBase.get("api/produto/apresentacao/" + vParamProd)
    .then(function(response) {
      //console.log('obterProdutoApresenta: ', response.data);
      return response.data;
    })
    .catch(function(error) {
      console.error(error);
    });
};
