import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { HISTORICO_PED_COMPRA } from "../constants/action-types";

let initialState = {
  historicoCompras: [],
};

const painelControleReducer = (state = initialState, action) => {
  switch (action.type) {
    case HISTORICO_PED_COMPRA:
      return {
        ...state,
        historicoCompras: action.historicoCompras,
      };

    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: "Syndata-",
  key: "painel-control",
  storage,
};

export default persistReducer(persistConfig, painelControleReducer);
