import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    ADD_TO_WISHLIST, REFRESH_STORE, REMOVE_FROM_WISHLIST
} from "../constants/action-types";
import { findIndex } from "../utils";

const initialState = { list: [] };

const wishlistReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case ADD_TO_WISHLIST:
      if (!findIndex(state.list, action.produto.id)) {
        return {
          ...state,
          list: [...state.list, action.produto],
        };
      }
      return state;

    case REMOVE_FROM_WISHLIST:
      return {
        list: state.list.filter((produto) => produto.id !== action.produto.id),
      };

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: "Syndata-",
  key: "wishlists",
  storage,
};

export default persistReducer(persistConfig, wishlistReducer);
