// Dados Estabelecimento
export const RECEBER_DADOS_ESTAB = "RECEBER_DADOS_ESTAB";
export const RECEBER_DADOS_SISTEMA = "RECEBER_DADOS_SISTEMA";

// Conta
export const RECEBER_LOGIN = "RECEBER_LOGIN";
export const RECEBER_CONTA_USUARIO = "RECEBER_CONTA_USUARIO";

// Categorias
export const FETCH_SINGLE_CATEGORIA = "FETCH_SINGLE_CATEGORIA";
export const RECEBER_CATEGORIAS = "RECEBER_CATEGORIAS";

// Get products
export const FETCH_SINGLE_PRODUTO = "FETCH_SINGLE_PRODUTO";
export const RECEBER_PRODUTOS_DESTAQUE = "RECEBER_PRODUTOS_DESTAQUE";
export const RECEBER_PRODUTOS_PESQUISA = "RECEBER_PRODUTOS_PESQUISA";
export const RECEBER_PRODUTO_APRESENTA = "RECEBER_PRODUTO_APRESENTA";
export const SHOW_QUICKVIEW = "SHOW_QUICKVIEW";
export const HIDE_QUICKVIEW = "HIDE_QUICKVIEW";

// Filter Products
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_COLOR = "FILTER_COLOR";
export const FILTER_PRICE = "FILTER_PRICE";
export const FILTER_CATEGORIES = "FILTER_CATEGORIES";
export const FILTER_SIZE = "FILTER_SIZE";
export const SORT_BY = "SORT_BY";
export const FILTER_CLEAN_FILTERS = "FILTER_CLEAN_FILTERS";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";
export const CLEAR_CART = "CLEAR_CART";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";
export const REMOVE_ALL_COMPARE = "REMOVE_ALL_COMPARE";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";
export const CHECKOUT_OPCAO_FRETE = "CHECKOUT_OPCAO_FRETE";
export const CHECKOUT_SELEC_FRETE = "CHECKOUT_SELEC_FRETE";
export const CHECKOUT_LOCAL_ENTREGA = "CHECKOUT_LOCAL_ENTREGA";
export const CHECKOUT_PED_COMPRA_ATUAL = "CHECKOUT_PED_COMPRA_ATUAL";
export const CHECKOUT_PED_COMPRA_PEND = "CHECKOUT_PED_COMPRA_PEND";
export const HISTORICO_PED_COMPRA = "HISTORICO_PED_COMPRA";

//Demo change
export const HIDE_CART_MODAL = "HIDE_CART_MODAL";
export const SHOW_CART_MODAL = "SHOW_CART_MODAL";

//Newsletter PopUp Modal
export const HIDE_NEWSLETTER = "HIDE_NEWSLETTER";

//Refresh Store
export const REFRESH_STORE = "REFRESH_STORE";




