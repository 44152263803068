import ApiBase from "../api-base";

export const obterOpcaoFrete = function(
  vCepOrigem,
  vCepDestino,
  vListaProdFrete
) {
  const vParamFrete = {
    cepOrigem: vCepOrigem,
    cepDestino: vCepDestino,
    listaProduto: vListaProdFrete,
  };

  //console.log('obterOpcaoFrete:', vParamFrete);
  return ApiBase.put("api/pedido-compra/calc-frete", vParamFrete)
    .then(function(response) {
      let vResp = [];
      if (response.data) {
        for (const item of response.data) {
          vResp.push(item);
        }
      }
      return vResp;
    })
    .catch(function(error) {
      console.error(error);
      if (error.errorMessage != null) {
        throw error.errorMessage;
      } else {
        throw "Sem resposta do servidor web-commerce!";
      }
    });
};

export const obterProdutosPacoteFrete = function(
  vCepOrigem,
  vCepDestino,
  vListaProdFrete
) {
  const vParamFrete = {
    cepOrigem: vCepOrigem,
    cepDestino: vCepDestino,
    listaProduto: vListaProdFrete,
  };

  return ApiBase.put("api/pedido-compra/obter-parametros-frete", vParamFrete)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      console.error(error);
      if (error.errorMessage != null) {
        throw error.errorMessage;
      } else {
        throw "Sem resposta do servidor web-commerce!";
      }
    });
};

export const gerarIdentificadorCompra = function(numCpfComprador) {
  return ApiBase.get("api/pedido-compra/id-compra/" + numCpfComprador)
    .then(function(response) {
      //console.log('Ret gerarIdentificadorCompra: ', numCpfComprador, response.data);
      return response.data;
    })
    .catch(function(error) {
      console.error("gerarIdentificadorCompra:", error);
      if (error.errorMessage != null) {
        throw error.errorMessage;
      } else {
        throw "Sem resposta do servidor web-commerce!";
      }
    });
};

export const enviarPedidoCompra = function(vPedCompra) {
  //console.log('enviarPedidoCompra:', vPedCompra);
  return ApiBase.post("api/pedido-compra", vPedCompra)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      console.error("enviarPedidoCompra:", error);
      if (error.errorMessage != null) {
        throw error.errorMessage;
      } else {
        throw "Sem resposta do servidor web-commerce!";
      }
    });
};

export const verificarPedidoCompra = function(vIdPedCompra) {
  //console.log('verificarPedidoCompra:', vIdPedCompra);
  return ApiBase.get("api/pedido-compra/check-registrado/" + vIdPedCompra)
    .then(function(response) {
      //console.log('Ret verificarPedidoCompra: ', vIdPedCompra, response.data);
      const vResp = response.data;
      if (
        vResp &&
        vResp.msg &&
        vResp.msg.toLowerCase().startsWith("pedido registrado")
      ) {
        return true;
      } else {
        return false;
      }
    })
    .catch(function(error) {
      console.error("verificarPedidoCompra:", error);
      if (error.errorMessage != null) {
        throw error.errorMessage;
      } else {
        throw "Sem resposta do servidor web-commerce!";
      }
    });
};

export const verificarEstoqueDisponivel = function(listEstoque) {
  //console.log('verificarEstoqueDisponivel:', listEstoque);
  return ApiBase.put("api/pedido-compra/verifica-estoque", listEstoque)
    .then(function(response) {
      //console.log('Ret verificarEstoqueDisponivel: ', response.data);
      return response.data;
    })
    .catch(function(error) {
      console.error("verificarEstoqueDisponivel:", error);
      if (error.errorMessage != null) {
        throw error.errorMessage;
      } else {
        throw "Sem resposta do servidor web-commerce!";
      }
    });
};

export const obterHistoricoCompras = function(idComprador, numMeses) {
  return ApiBase.get(
    "api/pedido-compra/historico-ultimos-meses/" + idComprador + "/" + numMeses
  )
    .then(function(response) {
      //console.log('Ret obterHistoricoCompras: ', response.data);
      return response.data;
    })
    .catch(function(errorHist) {
      console.error("obterHistoricoCompras:", errorHist);
      if (errorHist.errorMessage != null) {
        throw errorHist.errorMessage;
      } else {
        throw "Sem resposta do servidor web-commerce!";
      }
    });
};

export const realizarPagamentoCartao = function(
  vPortador,
  vNumCartao,
  vValidadeMes,
  vValidadeAno,
  vCodSeguranca,
  vCompraCrt
) {
  const vCartaoCred = {
    numeroCartao: vNumCartao,
    codSeguranca: vCodSeguranca,
    validadeMes: vValidadeMes,
    validadeAno: vValidadeAno,
    nomePortador: vPortador,
    loginPortador: vCompraCrt.login,
  };

  const vCartao = {
    cartaoCredito: vCartaoCred,
    comprador: vCompraCrt.comprador,
    // numParcelas?: number;
    valorCompra: vCompraCrt.totalCompra,
    referenciaVenda: vCompraCrt.idCompra,
  };

  //console.log('realizarPagamentoCartao:', vCartao);
  return ApiBase.post("api/pagamento-online/cartao", vCartao)
    .then(function(response) {
      const vResp = response.data;
      /*if (vResp && vResp.id != null && vResp.retornoCod != null && vResp.retornoCod === '00') {                
                return true;
            } else {
                return false;
            }*/
      return vResp;
    })
    .catch(function(errorPagto) {
      console.error(
        "realizarPagamentoCartao:",
        errorPagto,
        errorPagto.errorMessage
      );
      if (errorPagto.errorMessage != null) {
        throw errorPagto.errorMessage;
      } else {
        throw "Sem resposta do servidor web-commerce!";
      }

      //return null;
    });
};