import axios from "axios";
import ApiBase from "../api-base";

let vLojaGlobal;

export const obterDadosEstabelecimento = function() {
  return axios
    .get("/assets/customizacao/estabelecimento.json")
    .then(function(response) {
      vLojaGlobal = response.data;
      return response.data;
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const obterConfigSistema = function(idLoja) {
  let idLojaSis;
  if (idLoja != null && idLoja > 0) {
    idLojaSis = idLoja;
  } else {
    //idLojaSis = vLojaGlobal.empresa.codLoja;
  }
  return ApiBase.get("api/config/sis/" + idLojaSis)
    .then(function(response) {
      //console.log('Ret obterConfigSistema: ', response.data);
      return response.data;
    })
    .catch(function(errorCfg) {
      console.error("obterConfigSistema:", errorCfg);
      if (errorCfg.errorMessage != null) {
        throw errorCfg.errorMessage;
      } else {
        throw "Não foi possível obter Config. Sistema. Sem resposta do servidor web-commerce!";
      }
    });
};
