//função para receber uma string e retorna um string sem acentos e sem caracteres especiais.
export function replaceSpecialChars(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]|[^0-9a-zA-Z\s]/g, "");
}

export function bloqueiaInput(str) {
  document.getElementById(str).setAttribute("readonly", true);
}

export function liberaInput(str) {
  if (document.getElementById(str).hasAttribute("readonly")) {
    document.getElementById(str).removeAttribute("readonly");
  }
}

export function formatarMoeda(num) {
  let vlNum = Number(num);
  let vlFormatada = vlNum.toFixed(2);
  return parseFloat(vlFormatada).toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
  });
}
