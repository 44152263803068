import ApiBase from "../api-base";

export const obterCategorias = function() {
  return ApiBase.get("api/departamento-produtos")
    .then(function(response) {
      let vResp = [];
      if (response.data) {
        for (const item of response.data) {
          if (item.indBloqueado && item.indBloqueado.includes("N")) {
            vResp.push(item);
          }
        }
      }
      return vResp;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });
};
