import axios from "axios";
import axiosRetry from "axios-retry";
import packageJson from "../../package.json";
import { receberContaAuth } from "../action";
import store from "../store";

//const linkApiWs = `http://783d07a4c467.sn.mynetname.net:48001/syndata-b2c/`; //`http://localhost:76/syndata-b2c/`;
//const linkApiWs = `http://localhost:73/syndata-b2c/`;
const linkApiWs = packageJson.apiurl;
let vBearerAuth;
let vLoginAuth;

export function setLoginAuth(vLogin) {
  //console.log('setLoginAuth:', vLogin);
  vLoginAuth = vLogin;
}

// recupear oAuthToken
function handleChangeContaConn() {
  const vStorageAuth = store.getState().contaUsuario.conta;
  if (vStorageAuth && vStorageAuth.access_token) {
    vBearerAuth = vStorageAuth;
  }
  //console.log('auth:', vStorageAuth, vBearerAuth)
}

store.subscribe(handleChangeContaConn);

function obterAutorizacaoRequisicao() {
  //console.log('obterAutorizacaoRequisicao', vBearerAuth)
  const USER_APP = "usr-app";
  const PAWD_APP = "102034";
  const dataBody =
    "grant_type=password&username=" +
    USER_APP +
    "&password=" +
    encodeURIComponent(PAWD_APP);
  const requestHeader = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT",
    "Access-Control-Allow-Credentials": "true",
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: "Basic " + btoa("syndata-client:syndata-secret"),
  };

  return axios
    .post(linkApiWs + "oauth/token", dataBody, { headers: requestHeader })
    .then(function(response) {
      //console.log('obterAutorizacaoRequisicao() Result: ', response);
      vBearerAuth = response.data;
      store.dispatch(receberContaAuth(vBearerAuth));
      return response.data;
    })
    .catch(function(error) {
      console.error("obterAutorizacaoRequisicao(): ", error);
      /*setTimeout(() => {
        window.location = process.env.PUBLIC_URL + "/";
      }, 10000);*/
    });
}

function gerarCabecalhoRequisicao() {
  let vIdComprador = "";
  if (vLoginAuth && vLoginAuth.id) {
    vIdComprador = vLoginAuth.id;
  }
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    Authorization: `Bearer ${
      vBearerAuth && vBearerAuth.access_token ? vBearerAuth.access_token : ""
    }`,
    "Id-Comprador": vIdComprador,
  };
}

const instanceAxiosBase = axios.create({
  baseURL: linkApiWs,
  // headers: gerarCabecalhoRequisicao()
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
  },
});

instanceAxiosBase.interceptors.request.use(
  async (config) => {
    //console.log('interceptors.request: ', config);
    if (document.body.classList.contains("loaded")) {
      document.body.classList.remove("loaded");
    }

    /*setTimeout(_reqResp => {          
    }, 10);*/
    
    while(vBearerAuth === undefined){
      vBearerAuth = await obterAutorizacaoRequisicao();
    }
    
    //console.log('OAuth2:', vBearerAuth);
    config.headers = gerarCabecalhoRequisicao();
    return config;
  },
  (error) => {
    console.error("interceptors.request", error, error.request);
    document.body.classList.add("loaded");
    Promise.reject(error.request);
  }
);

instanceAxiosBase.interceptors.response.use(
  function(response) {
    //console.log('interceptors.response: ', response);

    if (
      document.body &&
      document.body.classList &&
      !document.body.classList.contains("loaded")
    ) {
      document.body.classList.add("loaded");
    }

    return response;
  },
  function(error) {
    console.error("interceptors.response", error, error.response);    
    document.body.classList.add("loaded");

    if (error.response == null) {
      console.log('removido vBearerAuth')
      vBearerAuth = undefined;
      store.dispatch(receberContaAuth(vBearerAuth));
    }
    
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    } else if (error.response) {
      return Promise.reject(error.response);
    }
    return Promise.reject("Falha na Resposta Requisicao Servidor!");
  }
);

// instanceAxiosBase.defaults.headers.common['Authorization'] = `Bearer ${vBearerAuth.access_token}`;
axiosRetry(instanceAxiosBase, {
  retries: 6,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

export default instanceAxiosBase;
