import React from "react";
import { Route, Switch } from "react-router-dom";
import { HIDE_CART_MODAL, HIDE_QUICKVIEW } from "../constants/action-types";
import store from "../store";
import { initStickyOffset } from "../utils";

//let ProductsPages = React.lazy( () => import( './products-route' ) );
let ProdutosPages = React.lazy(() => import("./produtos-route"));
//let CategoriesPages = React.lazy( () => import( './categories-route' ) );
let OthersPages = React.lazy(() => import("./others-route"));
let HomePage = React.lazy(() => import("./home-route"));
let PesquisaPage = React.lazy(() => import("./pesquisa-route"));

export default function Routes() {
  store.dispatch({ type: HIDE_CART_MODAL });
  store.dispatch({ type: HIDE_QUICKVIEW });
  initStickyOffset();

  return (
    <React.Suspense fallback={<span></span>}>
      <Switch>
        <Route
          path={`${process.env.PUBLIC_URL}/produtos`}
          component={ProdutosPages}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/pesquisa`}
          component={PesquisaPage}
        />
        {/* <Route path={ `${ process.env.PUBLIC_URL }/categories` } component={ CategoriesPages } /> */}
        <Route
          path={`${process.env.PUBLIC_URL}/pagina`}
          component={OthersPages}
        />
        <Route path={`${process.env.PUBLIC_URL}/`} component={HomePage} />
      </Switch>
    </React.Suspense>
  );
}
