import React, { useEffect } from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import { PersistGate } from "redux-persist/integration/react";
// import action
import { obterEstabelecimentoConfig, obterEstabelecimentoConfigSis, obterProdutosDestaque,
  obterTodasCategorias, refreshStore } from "./action";
// import routes
import Routes from "./routes";
// import store
import store, { persistor } from "./store";
//import utils
import { definePolyfills, scrollTop } from "./utils";

export function Root() {
  definePolyfills();    
  scrollTop();

  useEffect(() => {
    if (store.getState().demo.current !== 1) {
      store.dispatch(refreshStore(1));

      store.dispatch(obterEstabelecimentoConfig());
      store.dispatch(obterTodasCategorias());
      store.dispatch(obterProdutosDestaque());
    } else {
      if (
        store.getState().estabelecimento != null &&
        store.getState().estabelecimento.dadosEstab
      ) {
        store.dispatch(
          obterEstabelecimentoConfigSis(
            store.getState().estabelecimento.dadosEstab.empresa.codLoja
          )
        );
      }
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<span></span>}>
        <BrowserRouter basename={"/"}>
          <ScrollContext>
            <Routes />
          </ScrollContext>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));