import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { liberaInput } from "../../src/utils/utils";
import * as apiCategoria from "../api/categoria";
import * as apiCheckout from "../api/checkout";
import * as apiEstabelecimento from "../api/estabelecimento";
import * as apiProduto from "../api/produto";
import * as apiLogin from "../api/usuario-login";
import * as types from "../constants/action-types";
import store from "../store";
import { exibirMsgErroEx, exibirMsgInfoEx, exibirMsgInfo } from "../utils";

/**
 * Receber Login
 */
export const receberLogin = (login) => ({
  type: types.RECEBER_LOGIN,
  login,
});

export const receberContaAuth = (conta) => ({
  type: types.RECEBER_CONTA_USUARIO,
  conta,
});

/**
 * Realizar Login
 */
export const realizarLogin = (email, senha, inputEmail, inputSenha) =>
  new Promise((resolveLogin, reject) => {
    apiLogin.realizarLogin(email, senha).then((respLogin) => {
      store.dispatch(receberLogin(respLogin));
      if (respLogin == null) {
        toast.error("Usuário e/ou Senha inválido!", {
          closeButton: true,
          autoClose: 8 * 1000,
        });
        if(inputEmail !== null || inputEmail !== ""){
          liberaInput(inputEmail);
          liberaInput(inputSenha);
        }        
      } else {  
        exibirMsgInfoEx("Sucesso!", `Usuário logado.`, 5);       
        //toast.success("Usuário logado!");
      }
      return resolveLogin(respLogin);
    });
  });

export const realizarLogout = (exibirMsg) =>
  new Promise((resolveLogout, reject) => {
    store.dispatch(receberLogin(null));

    if (exibirMsg == null || exibirMsg) {
      store.dispatch(receberOpcaoFrete(null));
      store.dispatch(receberSelecFrete({ servico: null, cep: null }));
      toast.info("Usuário saiu do sistema com segurança!");
    }

    return resolveLogout();
  });

/**
 * Atualizar Conta Login
 */
export const atualizarContaUsuario = (vDadosUser) =>
  new Promise((resolveLogin, reject) => {
    apiLogin.atualizarConta(vDadosUser).then((respConta) => {
      if (respConta && respConta.includes("Sucesso")) {
        store.dispatch(receberLogin(vDadosUser)); 
        exibirMsgInfoEx("Sucesso!", `Dados de Usuário atualizado.`, 5);       
        //toast.success("Conta de Usuário atualizada!");
        return true;
      } else {
        //exibirMsgErroEx("Verifique os Dados", "Não foi possível atualizar dados do Usuário!", 5);
        //toast.error("Não foi possível atualizar dados do Usuário!", {closeButton: true, autoClose: 8 * 1000});
        return false;
      }
    });
  });

export const cadastrarContaUsuario = (vDadosUser) =>
  new Promise((resolveConta, reject) => {
    apiLogin
      .criarUsuarioComprador(vDadosUser)
      .then((respConta) => {
        if (respConta) {
          exibirMsgInfoEx("Sucesso!", `sua conta foi criada com sucesso.`, 5);   
          //toast.success("Parabéns... sua conta de acesso já está disponível.");
          //toast.success("Parabéns... sua conta foi criada com sucesso!");
          return resolveConta(true);
        } else {
          //exibirMsgErroEx("Opss...", "Algo deu errado. Tente novamente!", 5);
          //toast.error("Opps... Algo deu errado. Tente novamente!", {closeButton: true, autoClose: 8 * 1000});
          return resolveConta(false);
        }
      })
      .catch((errConta) => {
        exibirMsgErroEx("Verifique os Dados", errConta, 5);
      });
  });

/**
 * Atualizar Conta Login
 */
export const redefinirSenhaContaUsuario = (vDadosUser) =>
  new Promise((resolveLogin, reject) => {
    apiLogin.redefinirSenhaConta(vDadosUser).then((respConta) => {
      if (respConta && respConta.includes("Sucesso")) {
        toast.success("Senha de Login do Usuário atualizada!");
        return true;
      } else {
        toast.error("Não foi possível atualizar Senha do Usuário!", {
          closeButton: true,
          autoClose: 8 * 1000,
        });
        return false;
      }
    });
  });

/**
 * Recuperar Conta Login Email
 */
export const recuperarSenhaEmailContaUsuario = (vEmailUser) =>
  new Promise((resolveLogin, reject) => {
    apiLogin
      .resetSenhaConta(vEmailUser)
      .then((respConta) => {
        if (respConta && respConta.includes("Sucesso")) {
          const vInstrucaoSpan = `Foi gerado nova senha temporária e enviada em seu e-mail. 
                Aguarde um pouco e verifique seu e-mail. 
                Caso não encontre o e-mail na sua caixa de entrada verifique a sua caixa de SPAM.`;
          toast.success(vInstrucaoSpan, {
            closeButton: true,
            autoClose: 45 * 1000,
          });
          return true;
        } else {
          toast.error(
            "Não foi possível solicitar nova senha! Tente novamente.",
            { closeButton: true, autoClose: 8 * 1000 }
          );
          return false;
        }

        resolveLogin();
      })
      .catch((errReset) => {
        // " ::: " + errReset + " :::"
        exibirMsgErroEx("Verifique os Dados", errReset, 10);
      });
  });

/**
 * Receive all produtos
 * @param { Array } produtos
 */
export const receberProdutosDestaque = (produtos) => ({
  type: types.RECEBER_PRODUTOS_DESTAQUE,
  produtos,
});

export const receberProdutosPesquisa = (produtos) => ({
  type: types.RECEBER_PRODUTOS_PESQUISA,
  produtos,
});

export const receberProdutoApresenta = (produto) => ({
  type: types.RECEBER_PRODUTO_APRESENTA,
  produto,
});

/**
 * Refresh Store
 * @param {*} current
 */
export const refreshUnSafe = (current) => ({
  type: types.REFRESH_STORE,
  current,
});

/**
 * Dispatch Refresh Store
 * @param { Number } demo
 */
export const refreshStore = (current) => (dispatch) => {
  dispatch(refreshUnSafe(current));
};

/**
 * Receber todas Categorias
 * @param { Array } categorias
 */
export const receberCategorias = (categorias) => ({
  type: types.RECEBER_CATEGORIAS,
  categorias,
});

/**
 * Obter Categorias de Produtos
 */
/*export const obterTodasCategorias = () => (dispatch) => {
  apiCategoria.obterCategorias().then((listCateg) => {
    dispatch(receberCategorias(listCateg));
    return listCateg;
  });
};*/

export const obterTodasCategorias = () => (dispatch) =>
  new Promise((resolve, reject) => {
    apiCategoria.obterCategorias().then((listCateg) => {
      dispatch(receberCategorias(listCateg));
      resolve(listCateg);
    });
  });

/**
 * Produtos em Destaque
 */
/*export const obterProdutosDestaque = () => (dispatch) => {
  apiProduto.obterProdutosDestaque().then((produtos) => {
    dispatch(receberProdutosDestaque(produtos));
    // dispatch( receberProdutosPesquisa( null ) );
    return produtos;
  });
};*/

export const obterProdutosDestaque = () => (dispatch) =>
  new Promise((resolve, reject) => {
    apiProduto.obterProdutosDestaque().then((produtos) => {
      dispatch(receberProdutosDestaque(produtos));
      resolve(produtos);
      // dispatch( receberProdutosPesquisa( null ) );
      //return produtos;
    });
  });

/**
 * Get all produtos
 */
export const obterProdutosPesquisa = (descricao, filtroCateg, filtroOrdem, numPag) =>
  new Promise((resolve, reject) => {
    //console.log('obterProdutosPesquisa Param:', descricao, filtroCateg, filtroOrdem);
    apiProduto
      .obterProdutosPesquisa(descricao, filtroCateg, filtroOrdem, numPag)
      .then((produtos) => {
        store.dispatch(receberProdutosPesquisa(produtos));
        resolve(produtos);
        //return produtos;
      });
  });

export const obterProdutoApresenta = (idProd) =>
  new Promise((resolve, reject) => {
    apiProduto.obterProdutoApresenta(idProd).then((produto) => {
      store.dispatch(receberProdutoApresenta(produto));
      resolve(produto);
    });
  });

/**
 * Get a Product
 * @param { String } produtoId
 */
export const getProduct = (produtoId) => ({
  type: types.FETCH_SINGLE_PRODUTO,
  produtoId,
});

/**
 * Show QuickView Modal
 * @param { Object } produto
 */
// export const showQuickView = ( produto ) => dispatch => {
//     dispatch({ type: types.SHOW_QUICKVIEW, produto });
// }
export const showQuickView = (produto) => ({
  type: types.SHOW_QUICKVIEW,
  produto,
});

/**
 * Hide QuickView Modal
 */
export const hideQuickView = () => (dispatch) => {
  dispatch({ type: types.HIDE_QUICKVIEW });
};

/****************** Cart Action *****************/

/**
 * Add to Cart
 * @param { Object } produto
 * @param { Number } qty
 */

export const addToCart = (produto, qty = 1) => (dispatch) => {
  dispatch({ type: types.SHOW_CART_MODAL, produto });
  dispatch({ type: types.ADD_TO_CART, produto, qty });
};

export function preparaAddToCart(
  itensCart = [],
  produto,
  qty,
  exibirModal,
  excluirWishLit
) {
  let isProductInCart = false;
  let produtcAdd = false;
  let isEstoqueDisponivel = false;
  let qtdTotal = 0;
  if (produto.segmento === "GRADE") {
    if (produto.codGradeTamanho !== "..") {
      produto.titulo = produto.titulo + " / " + produto.codGradeTamanho;
    }
    if (produto.codGradeCor !== "UNIC") {
      produto.titulo = produto.titulo + " - " + produto.descricaoCor;
    }
    if (itensCart.length > 0 && itensCart !== null) {
      for (let i = 0; i < itensCart.length; i++) {
        if (itensCart[i].codGrade === produto.codGrade) {
          qtdTotal = itensCart[i].qty + qty;
          isEstoqueDisponivel = qtdTotal <= produto.estoqueGrade ? true : false;
          isProductInCart = true;
          break;
        }
      }
    }
  } else {
    if (itensCart.length > 0 && itensCart !== null) {
      for (let i = 0; i < itensCart.length; i++) {
        if (itensCart[i].id === produto.id) {
          qtdTotal = itensCart[i].qty + qty;
          isEstoqueDisponivel =
            qtdTotal <= produto.estoqueDisponivel ? true : false;
          isProductInCart = true;
          break;
        }
      }
    }
  }
  if (isProductInCart) {
    if (isEstoqueDisponivel) {
      produtcAdd = true;
      store.dispatch({ type: types.ADD_TO_CART, produto, qty });
      exibirMsgInfoEx("Quantidade Adicionada!", "Quantidade Total " + qtdTotal + ".", 5);
    } else {
      exibirMsgErroEx("Quantidade indisponível!", "Estoque Total do Produto " +
          (produto.segmento === "GRADE" ? produto.estoqueGrade : produto.estoqueDisponivel) + ".", 3);
    }
  } else {
    if (produto.segmento === "GRADE") {
      isEstoqueDisponivel = produto.estoqueGrade >= qty ? true : false;
    } else {
      isEstoqueDisponivel = produto.estoqueDisponivel >= qty ? true : false;
    }
    if (isEstoqueDisponivel) {
      produtcAdd = true;
      store.dispatch({ type: types.ADD_TO_CART, produto, qty });
      exibirMsgInfoEx("Sucesso!", "Produto Adicionado ao Carrinho. ", 3);
    } else {
      exibirMsgErroEx(
        "Quantidade indisponível!",
        "Estoque Total do Produto " +
          (produto.segmento === "GRADE"
            ? produto.estoqueGrade
            : produto.estoqueDisponivel) +
          ".",
        3
      );
    }
  }
  if (produtcAdd) {
    if (exibirModal) {
      store.dispatch({ type: types.SHOW_CART_MODAL, produto });
    }
    if (excluirWishLit) {
      store.dispatch({ type: types.REMOVE_FROM_WISHLIST, produto });
    }
  }
}

export function verificaEstoque(itensCart = [], produto, qty) {
  let estoqueVerificado = {
    isEstoqueDisponivel: false,
    qtdDisponivel: 0,
  };
  let qtdTotal = 0;
  let inCart = false;
  estoqueVerificado = new Object();
  if (produto.segmento === "GRADE") {
    if (itensCart.length > 0 && itensCart !== null) {
      for (let i = 0; i < itensCart.length; i++) {
        if (itensCart[i].codGrade === produto.codGrade) {
          inCart = true;
          qtdTotal = itensCart[i].qty + qty;
          estoqueVerificado.isEstoqueDisponivel =
            qtdTotal <= produto.estoqueGrade ? true : false;
          estoqueVerificado.qtdDisponivel = produto.estoqueGrade;
          break;
        }
      }
      if (!inCart) {
        if (produto.estoqueGrade >= qty) {
          estoqueVerificado.isEstoqueDisponivel = true;
          estoqueVerificado.qtdDisponivel = produto.estoqueGrade;
        } else {
          estoqueVerificado.qtdDisponivel = produto.estoqueGrade;
        }
      }
    } else {
      if (produto.estoqueGrade >= qty) {
        estoqueVerificado.isEstoqueDisponivel = true;
        estoqueVerificado.qtdDisponivel = produto.estoqueGrade;
      } else {
        estoqueVerificado.qtdDisponivel = produto.estoqueGrade;
      }
    }
  } else {
    if (itensCart.length > 0 && itensCart !== null) {
      for (let i = 0; i < itensCart.length; i++) {
        if (itensCart[i].id === produto.id) {
          qtdTotal = itensCart[i].qty + qty;
          estoqueVerificado.isEstoqueDisponivel =
            qtdTotal <= produto.estoqueDisponivel ? true : false;
          estoqueVerificado.qtdDisponivel = produto.estoqueDisponivel;
          break;
        }
      }
    } else {
      estoqueVerificado.isEstoqueDisponivel =
        qty <= produto.estoqueDisponivel ? true : false;
      estoqueVerificado.qtdDisponivel = produto.estoqueDisponivel;
    }
  }
  return estoqueVerificado;
}

/**
 * Add to Cart in modal
 * @param { Object } produto
 * @param { Number } qty
 */
export const quickAddToCart = (produto, qty = 1) => (dispatch) => {
  //toast.success( "Item adicionado no Carrinho \n" + produto.titulo );
  //dispatch( { type: types.PRODUCT_IN_CART, produto, qty } );
  dispatch({ type: types.ADD_TO_CART, produto, qty });
};

/**
 * Add item in Wishlist to Cart
 * @param { Object } produto
 * @param { Number } qty
 */
export const addToCartAndRemoveWishlist = (produto, qty) => (dispatch) => {
  // toast.success( "Item adicionado no Carrinho \n" + produto.titulo );
  if (produto.qty + 1 <= produto.estoqueDisponivel) {
    dispatch({ type: types.ADD_TO_CART, produto, qty });
    dispatch({ type: types.REMOVE_FROM_WISHLIST, produto });
    exibirMsgInfoEx(
      "Item adicionado no Carrinho ",
      "Quantidade Total " + (produto.qty + 1) + ".",
      3
    );
  }
};

/**
 * Remove item from Cart
 * @param { Object } produto
 */
export const removeFromCart = (produto) => (dispatch) => {
  //toast.error("Item Removido do Carrinho \n" + produto.titulo);
  dispatch({ type: types.REMOVE_FROM_CART, produto });
};

export function preparaRemoveFromCart(produto, pagina) {
  if (
    pagina === "Fechamento da Compra - Pagamento" ||
    pagina === "Fechamento da Compra - Comprador"
  ) {
    //window.location.replace="http://localhost:3000/pagina/carrinho";

    exibirMsgErroEx(
      "Operação Inválida!",
      "Produtos não podem ser removidos na confirmação de entrega ou pagamento.",
      5
    );
  } else {
    store.dispatch({ type: types.REMOVE_FROM_CART, produto });
    exibirMsgInfoEx("Sucesso!", "Produto " + produto.titulo + " Removido Do Carrinho. ", 5);
  }
}

/**
 * Clear Cart
 * @param { Object } produto
 */
export const clearCart = (produto) => (dispatch) => {
  //toast.error( "Itens do Carrinho Removido!" );
  dispatch({ type: types.CLEAR_CART, produto });
};

/**
 * Increment quantity of item
 * @param { Object } produto
 * @param { Number } qty
 */
export const incrementQty = (produto) => (dispatch) => {
  let qtdTotal = 0;
  if (produto.segmento === "GRADE") {
    qtdTotal = produto.estoqueGrade;
  } else {
    qtdTotal = produto.estoqueDisponivel;
  }
  if (produto.qty + 1 <= qtdTotal) {
    dispatch({ type: types.ADD_TO_CART, produto, qty: 1 });
    exibirMsgInfoEx(
      "Quantidade Adicionada!",
      "Quantidade Total " + (produto.qty + 1) + ".",
      3
    );
  } else {
    exibirMsgErroEx(
      "Quantidade indisponível!",
      "Estoque Total do Produto " + qtdTotal + ".",
      3
    );
  }
};

/**
 * Decrement quantity of item
 * @param { String } produto
 */
export const decrementQty = (produto) => (dispatch) => {
  if (produto.qty > 1) {
    dispatch({ type: types.DECREMENT_QTY, produto });
    exibirMsgInfoEx(
      "Quantidade atualizada!", `Quantidade Total ${produto.qty - 1}.`,3);
  }  
};

/********************* Wishlist Action *********************/

/**
 * Add item to Wishlist
 * @param { Object } produto
 */
export const addToWishList = (produto) => (dispatch) => {
  exibirMsgInfo(`Item Adicionado nos Favoritos.`, 5);   
  //toast.success("Item Adicionado nos Favoritos");
  dispatch({ type: types.ADD_TO_WISHLIST, produto });
};

/**
 * Remove item from Wishlist
 * @param { String } produtoId
 */
export const removeFromWishlist = (produto) => (dispatch) => {
  exibirMsgInfo(`Item Removido dos Favoritos.`, 5); 
  //toast.error("Item Removido dos Favoritos");
  dispatch({ type: types.REMOVE_FROM_WISHLIST, produto });
};

/**
 * Remove item from Cart and Add to Whishlist
 * @param { String } produto
 */
export const moveFromCartToWishlist = (produto) => (dispatch) => {
  exibirMsgInfo(`Item Movido para os Favoritos.`, 5); 
  //toast.success("Item Movido para os Favoritos");
  dispatch({ type: types.REMOVE_FROM_CART, produto });
  dispatch({ type: types.ADD_TO_WISHLIST, produto });
};

/**
 * Remove item from Wishlist and Add to Cart
 * @param { String } produto
 */
export const moveFromWishlistToCart = (produto) => (dispatch) => {
  exibirMsgInfo(`Item Movido para o Carrinho.`, 5); 
  //toast.success("Item Movido para o Carrinho");
  dispatch({ type: types.REMOVE_FROM_WISHLIST, produto });
  dispatch({ type: types.ADD_TO_CART, produto, qty: 1 });
};

/********************* Compare Action *********************/

/**
 * Add item to Compare List
 * @param { Object } produto
 */
export const addToCompare = (produtoId) => (dispatch) => {
  dispatch({ type: types.ADD_TO_COMPARE, produtoId });
};

/**
 * Remove item form Compare List
 * @param { String } produtoId
 */
export const removeFromCompare = (produtoId) => (dispatch) => {
  dispatch({ type: types.REMOVE_FROM_COMPARE, produtoId });
};

/**
 * Clear all compare produtos
 *
 */
export const clearAllCompare = () => (dispatch) => {
  dispatch({ type: types.REMOVE_ALL_COMPARE });
};

/********************* Filter Action *********************/

/**
 * Filter by Brand
 * @param { String }  brand
 */
export const filterBrand = (brand) => ({
  type: types.FILTER_BRAND,
  brand,
});

/**
 * Filter by Color
 * @param { String } color
 */
export const filterColor = (color) => ({
  type: types.FILTER_COLOR,
  color,
});

/**
 * Filter by Price
 * @param { Number } value
 */
export const filterPrice = (price) => ({
  type: types.FILTER_PRICE,
  price,
});

/**
 * Sort by
 * @param { String } sortBy
 */
export const filterSort = (sortBy) => ({
  type: types.SORT_BY,
  sortBy,
});

/**
 * Filter by Size
 * @param { Number } value
 */
export const filterSize = (size) => ({
  type: types.FILTER_SIZE,
  size,
});

/**
 * Filter by categories
 * @param { Number } value
 */
export const filterCategories = (category) => ({
  type: types.FILTER_CATEGORIES,
  category,
});

/********************* Newsletter Modal Action *********************/
export const hideNewsletter = () => (dispatch) => {
  dispatch({ type: types.HIDE_NEWSLETTER });
};

/**
 * Clean All Filter
 *
 */
export const cleanFilter = () => ({
  type: types.FILTER_CLEAN_FILTERS,
});

/**
 * Obter Dados Estabelecimento
 */
export const receberDadosEstab = (dadosEstab) => ({
  type: types.RECEBER_DADOS_ESTAB,
  dadosEstab,
});

export const obterEstabelecimentoConfig = () => (dispatch) => {
  apiEstabelecimento.obterDadosEstabelecimento().then((dadosEstab) => {
    dispatch(receberDadosEstab(dadosEstab));
    return dadosEstab;
  });
};

export const receberDadosSistema = (dadosConfigSistema) => ({
  type: types.RECEBER_DADOS_SISTEMA,
  dadosConfigSistema,
});

export const obterEstabelecimentoConfigSis = (vIdLoja) => (dispatch) => {
  apiEstabelecimento.obterConfigSistema(vIdLoja).then((dadosConfigSistema) => {
    dispatch(receberDadosSistema(dadosConfigSistema));
    return dadosConfigSistema;
  });
};

/**
 * Checkout
 *
 */
export const receberOpcaoFrete = (opcaoFrete) => ({
  type: types.CHECKOUT_OPCAO_FRETE,
  opcaoFrete,
});

export const receberSelecFrete = (selecFrete) => ({
  type: types.CHECKOUT_SELEC_FRETE,
  selecFrete,
});

export const receberLocalEntrega = (localEntrega) => ({
  type: types.CHECKOUT_LOCAL_ENTREGA,
  localEntrega,
});

export const receberPedCompraAtual = (pedCompraAtual) => ({
  type: types.CHECKOUT_PED_COMPRA_ATUAL,
  pedCompraAtual,
});

export const receberPedCompraPendente = (pedCompraPend) => ({
  type: types.CHECKOUT_PED_COMPRA_PEND,
  pedCompraPend,
});

export const receberHistoricoPedCompra = (historicoCompras) => ({
  type: types.HISTORICO_PED_COMPRA,
  historicoCompras,
});

//COTAÇÃO FRETE

/*export const doCheckoutOpcaoFrete = (
  vCepOrigem,
  vCepDestino,
  vListaProdFrete
) =>
  new Promise((resolve, reject) => {
    console.log(
      "doCheckoutOpcaoFrete",
      vCepOrigem,
      vCepDestino,
      vListaProdFrete
    );
    if (vCepOrigem == null || vCepOrigem.length < 8) {
      toast.error("Não foi identificado CEP Lojista para processar o frete!", {
        closeButton: true,
        autoClose: 5 * 1000,
      });
      return resolve();
    }
    if (vCepDestino == null || vCepDestino.length < 8) {
      toast.error(
        "Não foi informado CEP do comprador para processar o frete!",
        { closeButton: true, autoClose: 5 * 1000 }
      );
      return resolve();
    }
    let servFreteSel = null;
    if (store.getState().cartList.selecFrete) {
      servFreteSel = store.getState().cartList.selecFrete.servico;
    }

    /*apiCheckout.obterOpcaoFrete(vCepOrigem, vCepDestino, vListaProdFrete).then( retOpcFrete => {
        store.dispatch( receberOpcaoFrete( retOpcFrete ) );
        store.dispatch( receberSelecFrete( {servico: servFreteSel, cep: vCepDestino} ) );
        return resolve(retOpcFrete);
    } ).catch(errCep => {
        toast.error( "Não foi possível processar a opção de frete!", {closeButton: true, autoClose: 8 * 1000} );
        store.dispatch( receberOpcaoFrete( null ) );        
        store.dispatch( receberSelecFrete( {servico: null, cep: vCepDestino} ) );
    })
    apiCheckout
      .obterProdutosPacoteFrete(vCepOrigem, vCepDestino, vListaProdFrete)
      .then((retOpcFrete) => {
        if (!retOpcFrete) {
          return resolve(null);
        }

        if (retOpcFrete.listaPacote == null || retOpcFrete.listaPacote.length < 1) {
          toast.error(
            "Não foi possível processar a opção de frete! Medidas e Peso do Produto não definido.",
            { closeButton: true, autoClose: 8 * 1000 }
          );
          return resolve(null);
        }

        apiFreteMelhorEnvioApi
          .processarCotacaoFreteVolumeFME(
            { postal_code: vCepOrigem },
            { postal_code: vCepDestino },
            retOpcFrete.listaPacote
          )
          .then((vCotacaoMelhorEnvio) => {
            let vResultFrete = [];
            let vDiasEntrega = 0;
            let vPrecoEntrega = 0;

            if (vCotacaoMelhorEnvio) {
              for (const itemCotacao of vCotacaoMelhorEnvio) {
                console.log("itemCotacao: action/index: ", itemCotacao);
                if (itemCotacao.id == null) continue;
                if (itemCotacao.error && itemCotacao.error.length > 0) {
                  console.log(
                    "Restrição Cotação Melhor Envio: " + itemCotacao.error
                  );
                  continue;
                }

                vDiasEntrega = itemCotacao.delivery_time;
                if (itemCotacao.custom_delivery_time > 0) {
                  vDiasEntrega = itemCotacao.custom_delivery_time;
                }

                vPrecoEntrega = itemCotacao.price;
                if (itemCotacao.custom_price > 0) {
                  vPrecoEntrega = itemCotacao.custom_price;
                }

                vResultFrete.push({
                  servico: {
                    id: itemCotacao.id,
                    companyId: itemCotacao.company.id,
                    descricao:
                      itemCotacao.company.name + " " + itemCotacao.name,
                    logo: itemCotacao.company.picture,
                  },
                  preco: Number(vPrecoEntrega),
                  prazoDias: vDiasEntrega,
                  pacotes: itemCotacao.packages,
                });
              }
            }

            console.log("Result Frete: ", vResultFrete);
            store.dispatch(receberOpcaoFrete(vResultFrete));
            store.dispatch(
              receberSelecFrete({ servico: servFreteSel, cep: vCepDestino })
            );
            return resolve(vResultFrete);
          })
          .catch((errMelhorEnvio) => {
            toast.error(
              "Não foi possível processar a opção de frete no Melhor Envio!",
              { closeButton: true, autoClose: 8 * 1000 }
            );
            store.dispatch(receberOpcaoFrete(null));
            store.dispatch(
              receberSelecFrete({ servico: null, cep: vCepDestino })
            );
          });
      })
      .catch((errCep) => {
        toast.error("Não foi possível processar a opção de frete!", {
          closeButton: true,
          autoClose: 8 * 1000,
        });
        store.dispatch(receberOpcaoFrete(null));
        store.dispatch(receberSelecFrete({ servico: null, cep: vCepDestino }));
      });
  });*/

export const doCheckoutSelecaoFrete = (vOpcaoFrete, vCep) =>
  new Promise((resolve, reject) => {
    store.dispatch(receberSelecFrete({ servico: vOpcaoFrete, cep: vCep }));
    return resolve();
  });

/*export const doCheckoutOpcaoFrete = (
  vCepOrigem,
  vCepDestino,
  vListaProdFrete
) =>
  new Promise((resolve, reject) => {
    //console.log('doCheckoutOpcaoFrete', vCepOrigem, vCepDestino, vListaProdFrete);
    if (vCepOrigem == null || vCepOrigem.length < 8) {
      toast.error("Não foi identificado CEP Lojista para processar o frete!", {
        closeButton: true,
        autoClose: 5 * 1000,
      });
      return resolve();
    }
    if (vCepDestino == null || vCepDestino.length < 8) {
      toast.error(
        "Não foi informado CEP do comprador para processar o frete!",
        { closeButton: true, autoClose: 5 * 1000 }
      );
      return resolve();
    }
    let servFreteSel = null;
    if (store.getState().cartList.selecFrete) {
      servFreteSel = store.getState().cartList.selecFrete.servico;
    }

    apiCheckout
      .obterOpcaoFrete(vCepOrigem, vCepDestino, vListaProdFrete)
      .then((retOpcFrete) => {
        store.dispatch(receberOpcaoFrete(retOpcFrete));
        store.dispatch(
          receberSelecFrete({ servico: servFreteSel, cep: vCepDestino })
        );
        return resolve(retOpcFrete);
      })
      .catch((errCep) => {
        toast.error("Não foi possível processar a opção de frete!", {
          closeButton: true,
          autoClose: 8 * 1000,
        });
        store.dispatch(receberOpcaoFrete(null));
        store.dispatch(receberSelecFrete({ servico: null, cep: vCepDestino }));
      });
  });

export const doCheckoutSelecaoFrete = (vOpcaoFrete, vCep) =>
  new Promise((resolve, reject) => {
    store.dispatch(receberSelecFrete({ servico: vOpcaoFrete, cep: vCep }));
    return resolve();
  }); */

//FIM COTAÇÃO FRETE

export const doCheckoutLocalEntrega = (vLocalEntrega) =>
  new Promise((resolve, reject) => {
    store.dispatch(receberLocalEntrega(vLocalEntrega));
    return resolve();
  });

export const doCheckoutVerificarEstoque = (vItensPedCompra, vCodLoja) =>
  new Promise((resolve, reject) => {
    if (vItensPedCompra == null || vItensPedCompra.length < 1) {
      return resolve(false);
    }

    let vListaProdEstoque = [];
    for (const itemPrd of vItensPedCompra) {
      vListaProdEstoque.push({
        codProduto: itemPrd.id,
        codLoja: vCodLoja,
        segmento: itemPrd.segmento,
        codGrade: itemPrd.codGrade,
      });
    }

    apiCheckout
      .verificarEstoqueDisponivel(vListaProdEstoque)
      .then((retEstoque) => {
        return resolve(retEstoque);
      })
      .catch((errCheckEstoque) => {
        console.error("Falha doCheckoutVerificarEstoque:", errCheckEstoque);
        toast.error(errCheckEstoque, {
          closeButton: true,
          autoClose: 8 * 1000,
        });
      });
  });

export const doCheckoutPedidoCompra = (vPedCompra) =>
  new Promise((resolve, reject) => {
    const vPedCompraEnvio = {
      idCompra: vPedCompra.idCompra,
      dataHora: new Date(),
      idLoja: vPedCompra.codLoja,
      freteVenda: vPedCompra.freteVenda,
      comprador: vPedCompra.comprador,
      totalBruto: vPedCompra.totalBruto,
      valorFrete: vPedCompra.valorFrete,
      totalCompra: vPedCompra.totalCompra,
      produtos: vPedCompra.listProdutos,
      pagamentos: vPedCompra.listPagamento,
      freteEcommerce: vPedCompra.freteEcommerce,
      isPedCatalogo: vPedCompra.isPedCatalogo,
      //idPedidoEnviado?: number;
      //statusPedidoEnviado?: number;
      //andamento?: string;
    };
    store.dispatch(receberPedCompraPendente(vPedCompraEnvio));
    apiCheckout
      .enviarPedidoCompra(vPedCompraEnvio)
      .then((retNewPedCompra) => {
        if (!retNewPedCompra) {
          toast.error("Pedido de Compra não enviado para o lojista!", {
            closeButton: true,
            autoClose: 8 * 1000,
          });
        }
        return resolve(retNewPedCompra != null);
      })
      .catch((errEnvioPed) => {
        console.error("Falha doCheckoutPedidoCompra:", errEnvioPed);
        toast.error("Não foi possível processar o pedido! " + errEnvioPed, {
          closeButton: true,
          autoClose: 8 * 1000,
        });
      });
  });

export const doCheckoutIniciarPedidoCompra = (vCompradorCpfCnpj) =>
  new Promise((resolve, reject) => {
    apiCheckout
      .gerarIdentificadorCompra(vCompradorCpfCnpj)
      .then((retIdCompra) => {
        store.dispatch(receberPedCompraAtual({ idCompra: retIdCompra }));
        resolve(retIdCompra);
      });
  });

export const doCheckoutPagamento = (
  vPortador,
  vNumCartao,
  vValidadeMes,
  vValidadeAno,
  vCodSeguranca,
  vCompraCrt
) =>
  new Promise((resolve, reject) => {
    apiCheckout
      .realizarPagamentoCartao(
        vPortador,
        vNumCartao,
        vValidadeMes,
        vValidadeAno,
        vCodSeguranca,
        vCompraCrt
      )
      .then((retPgtoCrt) => {
        /*if (retPgtoCrt) {
            return resolve(true);
        } else {
            return resolve(false);
        }*/
        resolve(retPgtoCrt);
      })
      .catch((errPagtoCrt) => {
        console.error("doCheckoutPagamento:", errPagtoCrt);
        toast.error(
          "Não foi possível processar o pagamento cartão! " + errPagtoCrt,
          { closeButton: true, autoClose: 8 * 1000 }
        );
      });
  });

export const doCheckoutFinalizar = () => (dispatch) => {
  /*toast.info( "Itens do Carrinho Removido!" );*/
  /*toast.info(
    "Já recebemos seu pedido! " +
      "Você será informado sobre o andamento da compra em seu e-mail.",
    { closeButton: true, autoClose: 6 * 1000 }
  );*/
  dispatch(dispatch({ type: types.CLEAR_CART, produto: [] }));
  dispatch(receberLocalEntrega(null));
  dispatch(receberOpcaoFrete(null));
  dispatch(receberSelecFrete({ servico: null, cep: null }));
  dispatch(receberPedCompraAtual(null));
  dispatch(receberPedCompraPendente(null));
};

export const doCheckoutVerificarPedidoCompraPendente = (vPedCompra) =>
  new Promise((resolve, reject) => {
    //toast.info('Verificando Pedido de Compra Pendente de Confirmação');
    console.log("doCheckoutVerificarPedidoCompraPendente:", vPedCompra);
    apiCheckout
      .verificarPedidoCompra(vPedCompra.idCompra)
      .then((retIdCompra) => {
        if (retIdCompra) {
          store.dispatch(clearCart([]));
          store.dispatch(receberLocalEntrega(null));
          store.dispatch(receberOpcaoFrete(null));
          store.dispatch(receberSelecFrete({ servico: null, cep: null }));
          store.dispatch(receberPedCompraAtual(null));
          store.dispatch(receberPedCompraPendente(null));
        }
        resolve(retIdCompra);
      });
  });

export const doHistoricoPedidoCompra = (vIdComprador, vNumMeses) =>
  new Promise((resolve, reject) => {
    apiCheckout
      .obterHistoricoCompras(vIdComprador, vNumMeses)
      .then((retHistCompra) => {
        //store.dispatch(receberHistoricoPedCompra(retHistCompra));
        resolve(retHistCompra);
      }).catch((retHistCompraErro) => {
        console.log(retHistCompraErro)
        return(retHistCompraErro);
      });
  });

/*export const doCheckoutPedidoEtiquetaFrete = (
  vNumPedidoCompra,
  idServico,
  dadosEstab,
  dadosDestinatario,
  listaProduto,
  listaVolume,
  valorSeguro
) =>
  new Promise((resolve, reject) => {
    const objDadosRemetente = {
      name: dadosEstab.nome,
      phone: dadosEstab.telefone,
      email: dadosEstab.email,
      document: null,
      company_document: "08957132000118", // falta cnpj dadosEstab
      state_register: "104149019", // inscr. estadual
      economic_activity_code: "4751201", // codigo CNAE obrigatoria para trans. LATAM CARGO.
      address: "Avenida T9", //
      complement: "QD 537 LT 06",
      number: "1730",
      district: "Jardim América",
      city: "Goiânia",
      country_id: "BR",
      postal_code: "74255220",
      note: "",
    };

    const objDadosDestinatario = {
      name: dadosDestinatario.comprador,
      phone: dadosDestinatario.telefone,
      email: dadosDestinatario.email,
      //document: dadosDestinatario.cpf,
      document: "51314106651",
      company_document: dadosDestinatario.cnpj,
      state_register: dadosDestinatario.inscEstadual,
      address: dadosDestinatario.logradouro,
      complement: dadosDestinatario.complemento,
      number: dadosDestinatario.numero,
      district: dadosDestinatario.bairro,
      city: dadosDestinatario.cidade,
      state_abbr: dadosDestinatario.uf,
      country_id: "BR",
      postal_code: dadosDestinatario.cep,
      note: null,
    };
    const options = {
      insurance_value: 12.15,
      receipt: false,
      own_hand: false,
      reverse: false,
      non_commercial: true,
      invoice: {
        key: null,
        //key: "52210508957132000118551110000000051030849435"
      },
      platform: "e-commerce syndata",
      tags: { tag: "102035556", url: null },
    };

    apiFreteMelhorEnvioApi
      .addEtiquetaCarrinhoFME(
        vNumPedidoCompra,
        idServico,
        null,
        objDadosRemetente,
        objDadosDestinatario,
        listaProduto,
        listaVolume,
        valorSeguro,
        options
      )
      .then((retAddCarrinhoFrete) => {
        resolve(retAddCarrinhoFrete);
      });
  });*/
