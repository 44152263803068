import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
    FETCH_SINGLE_PRODUTO, HIDE_QUICKVIEW, RECEBER_PRODUTOS_DESTAQUE,
    RECEBER_PRODUTOS_PESQUISA,
    RECEBER_PRODUTO_APRESENTA, REFRESH_STORE, SHOW_QUICKVIEW
} from "../constants/action-types";
import { findIndex } from "../utils";

const initialState = {
  produtos: [],
  pesquisa: [],
  produtoApresenta: {},
  single: {},
  quickShow: false,
  fetchBegin: true,
};

const produtoReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEBER_PRODUTOS_DESTAQUE:
      return { ...state, produtos: action.produtos };

    case RECEBER_PRODUTOS_PESQUISA:
      return { ...state, pesquisa: action.produtos };

    case RECEBER_PRODUTO_APRESENTA:
      return { ...state, produtoApresenta: action.produto };

    case SHOW_QUICKVIEW:
      return { ...state, single: action.produto, quickShow: true };

    /*case SHOW_QUICKVIEW:
            return { ...state, single: action.produto, quickShow: true };*/

    case HIDE_QUICKVIEW:
      return { ...state, quickShow: false };

    case FETCH_SINGLE_PRODUTO:
      if (findIndex(state.produtos, action.produtoId)) {
        const single = state.produtos.reduce((itemAcc, produto) => {
          return produto;
        }, []);
        return { ...state, single: single };
      }
      break;

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: "Syndata-",
  key: "produtos",
  storage,
};

export default persistReducer(persistConfig, produtoReducer);
