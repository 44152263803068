import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  ADD_TO_CART,
  CHECKOUT_LOCAL_ENTREGA,
  CHECKOUT_OPCAO_FRETE,
  CHECKOUT_PED_COMPRA_ATUAL,
  CHECKOUT_PED_COMPRA_PEND,
  CHECKOUT_SELEC_FRETE,
  CLEAR_CART,
  DECREMENT_QTY,
  HIDE_CART_MODAL,
  REFRESH_STORE,
  REMOVE_FROM_CART,
  SHOW_CART_MODAL
} from "../constants/action-types";
import { findIndex, findIndexGrade } from "../utils";

let initialState = {
  cart: [],
  modalProduct: "",
  showModal: false,
  opcaoFrete: [],
  selecFrete: null,
  localEntrega: {},
  pedCompraAtual: null,
  pedCompraPend: null,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      let productInCart = false;
      if (action.produto.segmento === "GRADE") {
        productInCart = findIndexGrade(state.cart, action.produto.codGrade);
      } else {
        productInCart = findIndex(state.cart, action.produto.id);
      }
      if (productInCart) {
        const cart = state.cart.reduce((acc, produto) => {
          if (produto.id === action.produto.id) {
            let qtdSolicitada = produto.qty + action.qty;
            qtdSolicitada = produto.qty + action.qty;
            if (produto.segmento === "GRADE") {
              if (produto.codGrade === action.produto.codGrade) {
                if (qtdSolicitada > action.produto.estoqueGrade) {
                  acc.push({
                    ...produto,
                    qty: produto.qty,
                    sum:
                      (action.produto.salePrice
                        ? action.produto.salePrice
                        : action.produto.price) * produto.qty,
                  });
                } else {
                  acc.push({
                    ...produto,
                    qty: produto.qty + action.qty,
                    sum:
                      (action.produto.salePrice
                        ? action.produto.salePrice
                        : action.produto.price) *
                      (produto.qty + action.qty),
                  });
                }
              } else {
                acc.push(produto);
              }
            } else {
              if (qtdSolicitada > action.produto.estoqueDisponivel) {
                acc.push({
                  ...produto,
                  qty: produto.qty,
                  sum:
                    (action.produto.salePrice
                      ? action.produto.salePrice
                      : action.produto.price) * produto.qty,
                });
              } else {
                acc.push({
                  ...produto,
                  qty: produto.qty + action.qty,
                  sum:
                    (action.produto.salePrice
                      ? action.produto.salePrice
                      : action.produto.price) *
                    (produto.qty + action.qty),
                });
              }
            }
          } else {
            acc.push(produto);
          }
          return acc;
        }, []);
        return { ...state, cart, opcaoFrete: [], selecFrete: null };
      }

      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.produto,
            qty: action.qty,
            sum:
              (action.produto.salePrice
                ? action.produto.salePrice
                : action.produto.price) * action.qty,
          },
        ],
        opcaoFrete: [],
        selecFrete: null
      };

    case REMOVE_FROM_CART:
      if (action.produto.segmento === "GRADE") {
        return {
          ...state,
          cart: state.cart.filter(
            (item) => item.codGrade !== action.produto.codGrade
          ),
        };
      } else {        
        return {
          ...state,
          cart: state.cart.filter((item) => item.id !== action.produto.id),
          opcaoFrete: [],
          selecFrete: null
        };
      }

    case CLEAR_CART:
      return {
        ...state,
        cart: [],
        opcaoFrete: [],
        selecFrete: null,
        localEntrega: null,
      };

    case DECREMENT_QTY:
      const cart = state.cart.reduce((acc, produto) => {
        if (produto.id === action.produto.id) {
          if(produto.segmento === "GRADE"){
            if(produto.codGrade === action.produto.codGrade){
              acc.push({
                ...produto,
                qty: produto.qty - 1,
                sum:
                  (action.produto.salePrice
                    ? action.produto.salePrice
                    : action.produto.price) *
                  (produto.qty - 1),
              });
            }else{
              acc.push(produto);
            }
          }else{
            acc.push({
              ...produto,
              qty: produto.qty - 1,
              sum:
                (action.produto.salePrice
                  ? action.produto.salePrice
                  : action.produto.price) *
                (produto.qty - 1),
            });
          }          
        } else {
          acc.push(produto);
        }
        return acc;
      }, []);
      return { ...state, cart, opcaoFrete: [], selecFrete: null };
    

    case CHECKOUT_OPCAO_FRETE:
      return {
        ...state,
        opcaoFrete: action.opcaoFrete,
      };

    case CHECKOUT_SELEC_FRETE:
      return {
        ...state,
        selecFrete: action.selecFrete,
      };

    case CHECKOUT_LOCAL_ENTREGA:
      return {
        ...state,
        localEntrega: action.localEntrega,
      };

    case CHECKOUT_PED_COMPRA_ATUAL:
      return {
        ...state,
        pedCompraAtual: action.pedCompraAtual,
      };
    case CHECKOUT_PED_COMPRA_PEND:
      return {
        ...state,
        pedCompraPend: action.pedCompraPend,
      };

    case SHOW_CART_MODAL:
      return {
        ...state,
        showModal: true,
        modalProduct: action.produto,
      };

    case HIDE_CART_MODAL:
      return {
        ...state,
        showModal: false,
      };

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: "Syndata-",
  key: "cartlist",
  storage,
};

export default persistReducer(persistConfig, cartReducer);
