import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  FETCH_SINGLE_CATEGORIA,
  RECEBER_CATEGORIAS
} from "../constants/action-types";
import { findIndex } from "../utils";

const initialState = {
  categorias: [],
  single: {},
  fetchBegin: true,
};

const categoriaReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEBER_CATEGORIAS:
      return { ...state, categorias: action.categorias };

    case FETCH_SINGLE_CATEGORIA:
      if (findIndex(state.categorias, action.categoriaId)) {
        const single = state.categorias.reduce((itemAcc, categoria) => {
          return categoria;
        }, []);
        return { ...state, single: single };
      }
      break;

    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: "Syndata-",
  key: "categorias",
  storage,
};

export default persistReducer(persistConfig, categoriaReducer);
