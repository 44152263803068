import React from "react";
import { removeXSSAttacks } from "../../utils";

function MsgCustom(props) {
  return (
    <div>
      <strong>{props.titulo}</strong>
      <br />
      <span dangerouslySetInnerHTML={removeXSSAttacks(props.mensagem)}></span>
    </div>
  );
}

export default MsgCustom;
